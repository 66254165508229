import {Button} from '@mantine/core'

interface TableStatusTextProps {
    status: boolean;
}

function TableStatusText({status}: TableStatusTextProps) {

    const okBackgroundColor = "#ECFEF3"
    const koBackgroundColor = "#FDECE9"
    const okColor = "#3C7C62"
    const koColor = "#FA5252"
    let [color, backgroundColor, txt] = ["", "", ""]
    switch(status) {
        case true:
            [color, backgroundColor, txt] = [okColor, okBackgroundColor, "Corretto"]
            break;
        default:
            [color, backgroundColor, txt] = [koColor, koBackgroundColor, "Errore"]
            break
    }


    return (
        <Button size="compact-md" radius={'lg'} style={{backgroundColor: backgroundColor, color: color}}>
            {txt}
        </Button>
    )
}

export default TableStatusText;