import {ArticleAlert} from "../../../../../../../interfaces/response/ArticleDetails";
import classes from "./SingleAlert.module.css"
import {Box, Flex} from "@mantine/core";

interface SingleAlertProps {
    alert: ArticleAlert
}

export default function SingleAlert({alert}: SingleAlertProps) {

    return (
        <Flex classNames={{
            root: classes.root
        }}>
            <Box className={classNames(alert.severity)}>
            </Box>
            <Box>
                {alert.message}
            </Box>
        </Flex>
    )
}

const classNames = (severity: string) => {
    switch (severity) {
        case "LOW": return classes.severity + " " +  classes.low;
        case "MEDIUM": return "severity medium";
        case "HIGH": return "severity high";
    }
}


