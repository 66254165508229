import NavBarComponent from "./navbar/NavbarComponent";
import Shell from "../Shell";
import {AppShell, Container, useMantineTheme} from "@mantine/core";
import React, {useState} from "react";
import header from "../Header";


/**
 * Represents the Shell Layout that is fixed throughout the back office
 * It takes the body as parameter
 * @param Body
 * @constructor
 */

interface ShellProps {
    Body: JSX.Element
}
export default function ShellLayout({Body}: ShellProps) {
    const theme = useMantineTheme();
    const [openedHeader, setOpenedHeader] = useState(false);
    return (
        <AppShell
            // styles={{
            //     main: {
            //         background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
            //     },
            // }}
            padding={0}
            navbar={{
                width: 300,
                breakpoint: 'sm',
            }}
        >
            <NavBarComponent />
            <AppShell.Main>
                {Body}
            </AppShell.Main>

        </AppShell>
    )
}