import {combineReducers} from "@reduxjs/toolkit";
import {serverApi} from "../services/RestService";
import mobileNavBarReducer from './slices/MobileNavBarSlice'
import InsertProductSelectedProductTypeReducer from './slices/InsertProductSelectedProductTypeSlice'
import ArticleModalStatusReducer from './slices/ArticleModalStatusSlice'

const reducers = combineReducers({
    mobileNavBar: mobileNavBarReducer,
    selectedProduct: InsertProductSelectedProductTypeReducer,
    articlesModalStatus: ArticleModalStatusReducer,
    [serverApi.reducerPath]: serverApi.reducer
});

export default reducers;
