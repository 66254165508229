import {Input, rem, Select, Switch, TextInput, useMantineTheme} from "@mantine/core";
import classes from './FormTextInput.module.css'
import {UseFormReturnType} from "@mantine/form";
import {GetInputPropsReturnType, UseForm} from "@mantine/form/lib/types";
import {useArticleDetailsForm} from "../ModalArticle";
import {ChangeEvent, useEffect, useState} from "react";
import {IconComponents} from "@tabler/icons-react";
import {MaterialSymbol} from "react-material-symbols";
import placeholder from "lodash/fp/placeholder";



interface SwitchProps {
    data?: string[]
    searchable?: boolean
    nothingFoundMessage?: string
    placeholder?: string
    checkIconPosition?: "left" | "right" | undefined
    rightSection?: any
    defaultValue?: string
}


/**
 * Component representing a text input. It takes some parameter and an optional form hook
 * if the component is part of a form object
 */
interface TextInputProps {
    disabled?: boolean,
    label: string,
    value?: string,
    setValue?: (value: string) => void;
    width?: string,
    options?: string[]
    placeholder?: string
    formInfo?: {
        formLabel: string,
        formContext: () => UseFormReturnType<any>
    }
}
export default function FormTextInput(props: TextInputProps) {

    const formLabel = props.formInfo?.formLabel ?? props.label;
    const theme = useMantineTheme()
    let input;

    const switchProps: SwitchProps = {}

    // Get whether the input is a normal textinput or a multiple choice
    const Input = props.options && props.options.length > 0
        ? Select
        : TextInput

    const icon =  <MaterialSymbol className={classes.selectIcon} size={18} color={theme.colors.textBlack[4]} fill icon={'expand_circle_down'}/>;




    const form = props.formInfo?.formContext();


    const initialValue = props.value !== undefined
        ? props.value
        : (form && props.formInfo)
            ? form.getValues()['current'][props.formInfo.formLabel]
            : '';

    // if(initialValue === "Bertazzoni La Germania") {
    //     // @ts-ignore
    //     console.log(`Initial Values: \n Props.Value: ${props.value} \n Form Label: ${formLabel} \n Form.getValues: ${form.getValues()[props.formInfo.formLabel]}`)
    // }
    const [value, setValue] = useState<string>(initialValue);




    //Sync state with props
    useEffect(() => {
        if (props.value !== undefined) {
            setValue(props.value);
        } else if (form && props.formInfo) {
            setValue(form.getValues()['current'][props.formInfo.formLabel]);
        }
    }, [value, form, props.formInfo, props.value]);


    const inputProps = form ? form.getInputProps(formLabel) : {};

    const handleChange = (event: ChangeEvent<HTMLInputElement> | string | null, option?: any) => {
        let newValue: string
        // newValue is required because the onChange method signature is different for TextInput and SelectComponent, so
        // get it based on the type of stuff it arrives as input in the handleChange function
        if (typeof event === 'string' || event === null) {
            newValue = event ?? '';
        } else {
            newValue = event.currentTarget.value;
        }
        setValue(newValue);
        if (props.setValue) {
            props.setValue(newValue);
        }
        if (form) {
            (inputProps as GetInputPropsReturnType).onChange(newValue);
        }
    };

    if (Input === Select) {
        switchProps.data = props.options
        switchProps.searchable = true
        switchProps.placeholder = "Seleziona un'opzione"
        switchProps.nothingFoundMessage = "Non trovato..."
        switchProps.checkIconPosition = "right"
        switchProps.rightSection = icon
        switchProps.defaultValue = value
    }
    return (
        <Input
            classNames={{
                wrapper: classes.wrapper,
                label: classes.label,
                dropdown: classes.dropdown,
                option: classes.option
            }}
            w={props.width ?? "100%"}
            disabled={props.disabled ?? false}
            variant="filled"
            key={formLabel}
            label={props.label}
            placeholder={props.placeholder ?? ""}
            defaultValue={value}
            {...switchProps}
            onChange={handleChange}
            {...inputProps}
        />
    );
}