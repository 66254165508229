import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {login} from "./PublicPages";
import {privatePages} from "./PrivatePages";
import {AuthenticatedTemplate} from "@azure/msal-react";


const privatePagesPaths = privatePages

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            {privatePagesPaths.map((page, index) => {
                return (
                    <Route
                        key={index}
                        path={page.path}
                        element={
                        <AuthenticatedTemplate>
                            <page.view />
                        </AuthenticatedTemplate>
                        }
                    />
                )
            })}
            <Route path={login.path} element={<login.view />} />

            <Route path={'/*'} element={<login.view />} />
        </Route>
    )
)
