import {Link, RichTextEditor} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import {StarterKit} from "@tiptap/starter-kit";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Tabs, Textarea} from "@mantine/core";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Image from '@tiptap/extension-image'
import {useDetailsFormContext} from "../../../pages/main/InserimentoDati";

interface TextEditorProps {
    formName: string;
    editable?: boolean;
    withRichMenu?: boolean;
    content?: string;
    setContent: Dispatch<SetStateAction<string>>
}

const FormTextEditor = (props: TextEditorProps) => {

    const sanitizeString = (s: string) => {
        return s.replace(/"/g, '""');
    }

    const form = useDetailsFormContext();

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            Image.configure({
                inline: true,
                allowBase64: true
            })
        ],

        content: props.content ?? '',
        editable: !!props.editable,
        onUpdate: (e) => {
            props.setContent(e.editor.getHTML())
            form.setFieldValue(props.formName, `"${sanitizeString(e.editor.getHTML())}"`);
        }
    });

    const [activeTab, setActiveTab] = useState<string | null>();

    useEffect(()=>{
        editor?.commands.setContent(props.content ?? '')
        form.setFieldValue(props.formName, `"${sanitizeString(editor?.getHTML() ?? "")}"`);
    }, [props.content]);

    return (

        <Tabs
            orientation="vertical"
            variant="pills"
            radius = "sm"
            color='dark'
            defaultValue="Preview">
            <Tabs.List w={{base: '120px', sm: '240px'}} style={{borderRight: '1px solid rgb(238,238,238)'}}>
                <Tabs.Tab value={"Preview"} pt={"xs"}>
                    Preview
                </Tabs.Tab>
                <Tabs.Tab value={"Codice"} pt={"xs"} >
                    Codice
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={"Preview"} pt={"xs"}>
                <RichTextEditor
                    m='15px'
                    mih='200px'
                    editor={editor}>
                    <RichTextEditor.Content />
                </RichTextEditor>
            </Tabs.Panel>

            <Tabs.Panel value={"Codice"} pt={"xs"}>
                <Textarea
                    style={{'& .mantine-Textarea-input': {minHeight: '200px'}}}
                    m='15px'
                    value={props.content}
                    onChange={(e) => {
                        props.setContent(e.currentTarget.value)
                        form.setFieldValue(props.formName, `"${sanitizeString(e.currentTarget.value)}"`);
                    }}
                />
            </Tabs.Panel>
        </Tabs>

    );
}

export default FormTextEditor;