import {InputFormDetail} from "../../../interfaces/form/InputFormDetail";
import {Select, TextInput, Checkbox} from "@mantine/core";
import {classiEnergetiche} from "../../inserimentoDati/Constants";


/**
 * The list of checkboxes for the otherDetails Tab
 */
export const defaultCheckboxes: InputFormDetail[] = [
    {
        label: 'Imballo Rovinato',
        formName: "FLAG MANUALE - Imballo Rovinato",
        type: Checkbox
    }
]