import {List} from "@mantine/core";

interface TableListMessagesProps {
    messages: string[]
}

function TableListMessages({messages}: TableListMessagesProps) {


    return (
        <List>
            {messages.map((message, key) => <List.Item>{message}</List.Item>)}
        </List>
    )

}

export default TableListMessages