import {Tabs} from "@mantine/core";
import React from "react";
import {articleList} from "./ArticleList";
import {InputForm} from "./InputForm";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../../redux/Store";
import {insertProductSelectedProductTypeActions} from "../../redux/slices/InsertProductSelectedProductTypeSlice";


const FormArticleDetails = () => {


    const activeTab = useSelector((state: Store) => state.selectedProduct);
    const dispatch = useDispatch()

    return (
        <Tabs
            orientation="vertical"
            variant="pills"
            value={activeTab}
            onChange={(e) => dispatch(insertProductSelectedProductTypeActions.set(e == null ? "" : e))}
            radius="sm"
            color='dark'>
            <Tabs.List w={{base: '120px', sm: '240px'}} style={{borderRight: '1px solid rgb(238,238,238)'}}>
                {articleList.map((article, key) => {
                    return <Tabs.Tab key={key} value={article.productType}>{article.productType}</Tabs.Tab>
                })}
            </Tabs.List>


            {articleList.map((article, key) => {
                return <Tabs.Panel key={key} value={article.productType} pt={"xs"}>
                        <InputForm productType={article.productType} content={article.content} />
                </Tabs.Panel>
            })}
        </Tabs>
    )
}

export default FormArticleDetails;