import {Tabs, useMantineTheme} from "@mantine/core";
import {MaterialSymbol} from "react-material-symbols";
import classes from "./ArticleDetailsTab.module.css";
import Articleinfo from "./articleInfo/ArticleInfo";
import {ArticleDetails} from "../../../../../interfaces/response/ArticleDetails";
import {useArticleDetailsForm} from "../ModalArticle";
import TechnicalDetails from "./technicalDetails/TechnicalDetails";
import Description from "./description/Description";
import Alerts from "./alerts/Alerts";
import {LoadingState} from "../../../../../interfaces/LoadingState";


interface ArticleDetailsTabProps {
    article: ArticleDetails
    loading: LoadingState,
    setLoading: (state: LoadingState) => void
}

export default function ArticleDetailsTab({article, loading, setLoading}: ArticleDetailsTabProps) {

    const theme = useMantineTheme()

    return (
        <Tabs
            classNames={{
                list: classes.tabList,
                root: classes.root,
                panel: classes.panel
            }}
            variant={"pills"} defaultValue={'articleInfo'}>
            <Tabs.List>
                <Tabs.Tab value={'articleInfo'} leftSection={<MaterialSymbol size={20} icon={'info'}/>}>
                    Informazioni Articolo
                </Tabs.Tab>
                <Tabs.Tab value={'technicalDetails'} leftSection={<MaterialSymbol size={20} icon={'find_in_page'}/>}>
                    Scheda Tecnica
                </Tabs.Tab>
                <Tabs.Tab value={'description'} leftSection={<MaterialSymbol size={20} icon={'description'}/>}>
                    Descrizione
                </Tabs.Tab>
                <Tabs.Tab value={'otherDetails'} leftSection={<MaterialSymbol size={20} icon={'quick_reference'}/>}>
                    Altri Dettagli
                </Tabs.Tab>
                <Tabs.Tab value={'alerts'} leftSection={<MaterialSymbol size={20} icon={'warning'}/>}>
                    Avvertimenti
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={"articleInfo"}>
                <Articleinfo article={article} />
            </Tabs.Panel>
            <Tabs.Panel value={"technicalDetails"}>
                <TechnicalDetails article={article} loading={loading} setLoading={setLoading}/>
            </Tabs.Panel>
            <Tabs.Panel value={"description"}>
                <Description article={article} loading={loading} setLoading={setLoading}/>
            </Tabs.Panel>
            <Tabs.Panel value={"alerts"}>
                <Alerts article={article} />
            </Tabs.Panel>
        </Tabs>
    )
}