import {Flex, Stack, Image, Center, useMantineTheme, AppShell, Space} from "@mantine/core";
import React from "react";
import {IconCamera, IconFileSpreadsheet} from '@tabler/icons-react';
import NavBarLink from "./NavBarLink";
import NavBarUserProfile from "./NavBarUserProfile";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {Store} from "../../../redux/Store";


function NavBarComponent() {
    const opened = useSelector((state: Store) => state.mobileNavBar)
    const navigate = useNavigate();
    const theme = useMantineTheme()
    return (
        <AppShell.Navbar hidden={!opened} w={{ sm: 200, lg: 300 }} bg={theme.colors.gray[0]}>
            <Space h={'xl'}></Space>
            <Flex direction={'column'} justify={'space-between'} style={{height: '100%'}}>
                    <AppShell.Section>
                        <Stack>
                            <Center mb={'36px'}>
                                <Image src={'/assets/logo-only-symbol.png'} maw={70}/>
                            </Center>
                            <Stack style={{gap: 0}}>
                                <NavBarLink icon={'category'} color={"aed581"} label={"Articoli"} url={"/articleDetails"} />
                                <NavBarLink icon={'add_to_photos'} color={"aed581"} label={"Immagini"} url={"/uploadImages"} />
{/*                                <NavBarLink icon={'csv'} color={"aed581"} label={"CSV"} url={"/uploadCsv"} />
                                <NavBarLink icon={'data_table'} color={"aed581"} label={"Inserimento Dati"} url={"/insertData"} />*/}
                            </Stack>
                        </Stack>

                    </AppShell.Section >
                    <AppShell.Section>
                        <NavBarUserProfile />
                    </AppShell.Section>
            </Flex>

        </AppShell.Navbar>
    )

}

export default NavBarComponent;