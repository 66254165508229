

export const toCSV = (content: Record<string, string>): string => {
    const keys = Object.keys(content)
    const values = Object.values(content)

    const header = keys.join(';')
    const row = values.join(';')

    return `${header}\n${row}`
}