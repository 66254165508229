
import classes from "./Description.module.css"
import {ArticleDetails} from "../../../../../../interfaces/response/ArticleDetails";
import {Box, Button, Flex, Stack, useMantineTheme} from "@mantine/core";
import React, {useState} from "react";
import {useGetDescriptionMutation} from "../../../../../../services/ArticleService";
import {useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import {Link} from "@mantine/tiptap";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import TextAlign from "@tiptap/extension-text-align";
import FormTextInput from "../../textInput/FormTextInput";
import FormTextEditor from "../../textEditor/FormTextEditor";
import {LoadingState} from "../../../../../../interfaces/LoadingState";


interface DescriptionProps {
    article: ArticleDetails,
    loading: LoadingState,
    setLoading: (state: LoadingState) => void
}


export default function Description({article, loading, setLoading}: DescriptionProps) {
    const theme = useMantineTheme()

    const initialValue = article.description

    const [descriptionInput, setDescriptionInput] = useState<string>("")
    const [descriptionContent, setDescriptionContent] = useState<string>(initialValue)
    const [description, isLoadingDescription] = useGetDescriptionMutation();


    const searchDescription = (input: string) => {
        setLoading({loading: true, completed: false, error: false})
        description({url: input})
            .unwrap()
            .then( (resp) => {
                console.log(resp)
                setDescriptionContent(resp.description)
                setLoading({loading: false, completed: true, error: false})
            })
        .catch( () => setLoading({loading: false, completed: false, error: true}))
    }



    // Text Editor properties
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ]
    });

    return (
        <Box className={classes.root}>
            <Stack h={"100%"}>
                <Flex justify={"space-between"} gap={"md"} h={"20%"}>
                    <FormTextInput
                        label={"Cerca per URL"}
                        placeholder={"https://"}
                        value={descriptionInput}
                        setValue={setDescriptionInput}/>
                    <Flex align={"flex-end"}>
                        <Button
                            onClick={() => searchDescription(descriptionInput)}
                            variant={"filled"}
                            radius={"lg"}
                            color={theme.colors.green[2]}
                            c={theme.colors.textBlack[4]}>
                            Cerca
                        </Button>
                    </Flex>
                </Flex>
                <Flex h={"80%"}>
                    <FormTextEditor formName={'DescrizioneHtml'} editable content={descriptionContent} setContent={setDescriptionContent}/>
                </Flex>
            </Stack>
        </Box>

    )
}