import {Flex, ThemeIcon, Text, useMantineTheme} from "@mantine/core";
import {MaterialSymbol} from "react-material-symbols";
import {useEffect, useState} from "react";
import {useLazyGetAllImageFoldersQuery} from "../../../services/ArticleService";
import {useLazyPingQuery} from "../../../services/PingService";


export default function ConnectionStatus() {

    const theme = useMantineTheme();
    const [getPing, isLoadingGetPing, isErrorGetPing] = useLazyPingQuery();
    const [isServerUp, setIsServerUp] = useState<boolean>(true)

    // useEffect(() => {
    //     const checkServerStatus = async () => {
    //         await getPing().then(resp => {
    //             if (resp.data !== undefined && resp.isSuccess) {
    //                 if (resp.data === 'OK') {
    //                     setIsServerUp(true)
    //                     return
    //                 }
    //             }
    //             setIsServerUp(false)
    //         })
    //     }
    //
    //     checkServerStatus()
    //
    //     const periodicCheck = setInterval(checkServerStatus, 5000)
    //
    //     return () => clearInterval(periodicCheck)
    // }, [getPing]);

    return (
        <Flex align={'start'}>
            <Flex align={'center'} gap={'sm'}>
                <ThemeIcon variant={'filled'} size={'xl'} radius={'xl'}
                           color={isServerUp ? theme.colors.green[2] : 'red'}
                           c={theme.colors.textBlack[4]}>
                    <MaterialSymbol icon={'database'} size={26}/>
                </ThemeIcon>

                <Text size={'xs'}>
                    {isServerUp ? (
                        <>
                            Connesso al <br /> Server
                        </>
                    ) : (
                        <>
                            Non connesso <br /> al Server
                        </>
                    )}
                </Text>
            </Flex>
        </Flex>


    )
}