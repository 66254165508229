import {createSlice} from "@reduxjs/toolkit";

const mobileNavBarSlice  = createSlice({
    name: "mobileNavBar",
    initialState: false,
    reducers: {
        switch: state => !state,
    }
})


const { reducer, actions } = mobileNavBarSlice

export const mobileNavBarActions = actions
export default reducer