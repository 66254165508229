import {serverApi} from './RestService';
import {ImageFolder} from "../interfaces/response/ImageFolder";
import {UploadFoldersForImageRequest} from "../interfaces/request/UploadFoldersForImageRequest";
import {CsvFile} from "../interfaces/response/CsvFile";
import {UploadFilesForCsvRequest} from "../interfaces/request/UploadFilesForCsvRequest";
import {CreateCSVRequest} from "../interfaces/request/CreateCSVRequest";
import {GetTechnicalDetailsByEANRequest} from "../interfaces/request/GetTechnicalDetailsByEANRequest";
import {GetTechnicalDetailsByURLRequest} from "../interfaces/request/GetTechnicalDetailsByURLRequest";
import {TechnicalDetailsResponse} from "../interfaces/response/TechnicalDetailsResponse";
import {DescriptionResponse} from "../interfaces/response/DescriptionResponse";
import {GetDescriptionByURLRequest} from "../interfaces/request/GetDescriptionByURLRequest";
import {ArticleDetails} from "../interfaces/response/ArticleDetails";


const articleService = serverApi.injectEndpoints({
    endpoints: builder => ({
        getAllImageFolders: builder.query<ImageFolder[], void>({
            query: () => '/images/folders'
        }),
        getAllCsvFiles: builder.query<CsvFile[], void>({
            query: () => 'csv/files'
        }),
        getArticleDetails: builder.query<ArticleDetails[], void>({
            query: () => 'article/details'
        }),
        uploadImageFolders: builder.mutation<string, UploadFoldersForImageRequest>(
            {
                query: (folders) => ({
                    url: 'images/upload',
                    method: 'POST',
                    body: folders
                })
            }
        ),
        uploadCsvFiles: builder.mutation<string, UploadFilesForCsvRequest>(
            {
                query: (files) => ({
                    url: 'csv/upload',
                    method: 'POST',
                    body: files
                })
            }
        ),
        uploadArticleData: builder.mutation<string, CreateCSVRequest>(
            {
                query: (content) => ({
                    url: 'data/upload',
                    method: 'POST',
                    body: content.content,
                    headers: {'SKU': content.code}
                })
            }
        ),
        createCsvFile: builder.mutation<string, CreateCSVRequest>(
            {
                query: (content) => ({
                    url: 'csv/create',
                    method: 'POST',
                    body: content.content,
                    headers: {'SKU': content.code}
                })
            }
        ),
        getTechnicalDetails: builder.mutation<TechnicalDetailsResponse, GetTechnicalDetailsByURLRequest>(
            {
                query: (request) => ({
                    url: 'details/technical',
                    method: 'POST',
                    body: request,
                })
            }
        ),
        getTechnicalDetailsByEAN: builder.mutation<TechnicalDetailsResponse, GetTechnicalDetailsByEANRequest>(
            {
                query: (request) => ({
                    url: 'details/technical/ean',
                    method: 'POST',
                    body: request,
                })
            }
        ),
        getTechnicalDetailsByURL: builder.mutation<TechnicalDetailsResponse, GetTechnicalDetailsByURLRequest>(
            {
                query: (request) => ({
                    url: 'details/technical/url',
                    method: 'POST',
                    body: request,
                })
            }
        ),
        getDescriptionByURL: builder.mutation<DescriptionResponse, GetDescriptionByURLRequest>(
            {
                query: (request) => ({
                    url: 'details/description/url',
                    method: 'POST',
                    body: request,
                })
            }
        ),
        getDescription: builder.mutation<DescriptionResponse, GetDescriptionByURLRequest>(
            {
                query: (request) => ({
                    url: 'details/description',
                    method: 'POST',
                    body: request,
                })
            }
        )
    })
})

export const {  useGetAllImageFoldersQuery, useLazyGetAllImageFoldersQuery,
                useUploadImageFoldersMutation,
                useGetAllCsvFilesQuery, useLazyGetAllCsvFilesQuery,
                useGetArticleDetailsQuery, useLazyGetArticleDetailsQuery,
                useUploadCsvFilesMutation,
                useCreateCsvFileMutation,
                useGetTechnicalDetailsMutation,
                useGetTechnicalDetailsByEANMutation,
                useGetTechnicalDetailsByURLMutation,
                useGetDescriptionByURLMutation,
                useGetDescriptionMutation,
                useUploadArticleDataMutation
} = articleService