import {useDetailsFormContext} from "../../../pages/main/InserimentoDati";
import {defaultCheckboxes} from "./OtherArticleDetailsList";
import {map} from "lodash";
import {Checkbox} from "@mantine/core";
import {useState} from "react";

interface FormCheckboxProps {

}

/**
 * This component renders a checkbox for a given form
 * @param props
 * @constructor
 */
const FormCheckbox = (props: FormCheckboxProps) => {

    const form = useDetailsFormContext();
    const [checked, setChecked] = useState(false);

    return (
        <>
            {defaultCheckboxes.map((checkbox, key) => {
                return <Checkbox
                    label={checkbox.label}
                    checked={checked}
                    onChange={(event) => {
                        setChecked(event.currentTarget.checked)
                        if (checkbox.formName && checked) {
                            form.setFieldValue(checkbox.formName, `"${checked ? "SI" : "NO"}`)
                        }
                    }}
                />
            })}
        </>

    )
}

export default FormCheckbox;