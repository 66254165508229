import {serverApi} from "./RestService";


const pingService = serverApi.injectEndpoints({
    endpoints: builder => ({
        ping: builder.query<string, void>({
            query: () => ({
                url: '/',
                responseHandler: "text"
            })
        })
    })
})

export const {
    usePingQuery, useLazyPingQuery
} = pingService