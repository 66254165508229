import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, MantineProvider, MantineThemeOther, MantineThemeOverride} from '@mantine/core';
import {Provider} from "react-redux";
import {store} from "./redux/Store";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./configuration/authConfig";
import {PublicClientApplication} from "@azure/msal-browser";
import {colors} from "./mantineThemeConfig";
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import 'mantine-datatable/styles.css'
import 'react-material-symbols/outlined';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);


const theme = createTheme({
    colors,
    fontFamily: 'Poppins'
})

root.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <MantineProvider theme={theme}>
                <App/>
            </MantineProvider>
        </MsalProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
