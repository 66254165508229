import {Flex, Image, Text} from '@mantine/core';


interface TableFileNameProps {
    text: string
}

function TableFileName({text}: TableFileNameProps) {

    let asset;
    if (!text.includes(".")) {
        asset = "assets/icon-folder.png"
    } else
        if (text.endsWith("csv")) {
            asset = "assets/icon-csv.png"
        }

    return (
        <Flex align={'center'}>

            <Image width={23} src={asset} />
            <Text style={{paddingLeft: '8px'}}>
                {text}
            </Text>
        </Flex>

    )
}

export default TableFileName;