import {MantineThemeColorsOverride} from "@mantine/core";



export const colors: MantineThemeColorsOverride = {
    gray: [
        "#eeeeee", // Table Borders / Sidebar Background Color / Table Articles Menu Hover / TextInput modal background
        "#a8b0b9", // Filter Icon in Article Table
        "#eaecee", // Filter/Search Border
        "#d6dade", // Search Text Input placeholder
        "#5f6265", // Sidebar Item text color
        "#F9F9F9", // The Modal background
        "#A1A1A1", // TextInput disabled text
        "#BEC7D2", // The border of the input selection in the modal
        "#586572",
        "#4F5B67" // Header Title
    ],
    dark: [
        "#f5f5f5",
        "#e7e7e7",
        "#cdcdcd",
        "#b2b2b2",
        "#9a9a9a",
        "#8b8b8b",
        "#848484",
        "#717171",
        "#656565",
        "#2D2D2D"
    ],
    green: [
        "#e9feea",
        "#d5f9d8",
        "#9FEEA7", // Sidebar Item Border Color [Selected], Database Connection Online, Modal Article Tabs color, Save modifications button
        "#9FEEA76b", // Sidebar Item Background Color [Selected]
        "#58e164",
        "#40dc4d",
        "#31da41",
        "#22c132",
        "#16ab2a",
        "#00941e"
    ],
    yellow: [
        "#FFCF0F",
        "#fff6cc",
        "#ffeb9b",
        "#ffe064",
        "#ffd738",
        "#ffd11c",
        "#ffce09",
        "#e3b600",
        "#c9a100",
        "#ae8b00"
    ],
    hover: [
        "#f5f5f526",
        "#e7e7e726",
        "#cdcdcd26",
        "#b2b2b226",
        "#9a9a9a26",
        "#8b8b8b26",
        "#84848426",
        "#71717126",
        "#65656526",
        "#57575726"
    ],
    textBlack: [
        '#373F47', // Page Titles
        '#242D35', // Table rows
        '#4f5b67', // Table Header,
        '#5F6265', // Sidebar Item Text color [normal]
        '#292D32', // Sidebar Item Text Color [Selected] / Modal TextInput label / Modal TextInput value / Modal tab text
    ]
}

