import {ArticleDetails} from "../../../../../interfaces/response/ArticleDetails";
import {
    Button,
    ThemeIcon,
    Text,
    Flex,
    Menu,
    useMantineTheme,
} from "@mantine/core";
import {MaterialSymbol} from "react-material-symbols";
import classes from "./ActionColumn.module.css";
import {useDisclosure} from "@mantine/hooks";
import ModalArticle from "../../modalArticle/ModalArticle";
import {memo, useEffect, useRef} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import {Store} from "../../../../../redux/Store";
import {ArticleModalStatusActions} from "../../../../../redux/slices/ArticleModalStatusSlice";


interface ActionColumnProps {
    article: ArticleDetails
}

const ActionColumn = ({article}: ActionColumnProps) => {

    const theme = useMantineTheme()


    const selectedArticle = useSelector((state: Store) => state.articlesModalStatus)
    const dispatch = useDispatch();

    const [modalOpened, {open: modalOpen, close: modalClose}] = useDisclosure(selectedArticle === article.headerArticleDetails.code)


    // const componentId = useRef(Math.random().toString(36).substr(2, 9));
    //
    // useEffect(() => {
    //     console.log(`MyComponent mounted with ID: ${componentId.current}`);
    // }, []);

    //console.log("Action Column: ", modalOpened)
    //console.log("Selected Article: ", selectedArticle)

    return (
        <>
            <ModalArticle article={article} modalOpened={modalOpened} modalClose={modalClose} />

            <Menu width={120} position="bottom" withArrow shadow="md" classNames={{item: classes.menuItem}}>
                <Menu.Target>
                    <Flex justify={'flex-end'}>
                        <Button variant={"transparent"} p={'0'}>
                            <ThemeIcon variant={"transparent"} color={"gray"}>
                                <MaterialSymbol icon={'more_horiz'} size={24}/>
                            </ThemeIcon>
                        </Button>
                    </Flex>

                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item leftSection={<MaterialSymbol icon={'edit_square'} size={16}/>} color={theme.colors.textBlack[1]}
                               onClick={(e) => {
                                   dispatch(ArticleModalStatusActions.set(article.headerArticleDetails.code ?? ""));
                                   modalOpen()}}>
                        <Text size={'xs'}>
                            Modifica
                        </Text>
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </>

    )
};

export default ActionColumn