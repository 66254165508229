import NavBarComponent from "../../../components/layoutComponents/navbar/NavbarComponent";
import HeaderComponent from "../../../components/Header";
import {Button, Container, Flex, Space, Text, Title, useMantineTheme} from "@mantine/core";
import {IconFileUpload} from "@tabler/icons-react";
import TableFolders from "../../../components/TableFolders";
import {ArticleColumns, ImageColumns} from "../../../interfaces/ImageTablePage";
import Shell from "../../../components/Shell";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useLazyGetArticleDetailsQuery} from "../../../services/ArticleService";
import {ArticleDetails} from "../../../interfaces/response/ArticleDetails";
import TableArticles from "../../../components/pageComponents/articoli/tableArticles/TableArticles";
import {TableArticleDetailsRow} from "../../../interfaces/tables/TableRows";
import ShellLayout from "../../../components/layoutComponents/ShellLayout";
import ConnectionStatus from "../../../components/layoutComponents/connectionStatus/ConnectionStatus";
import {DataTableColumn} from "mantine-datatable";
import {render} from "@testing-library/react";
import CategoryColumn from "../../../components/pageComponents/articoli/tableArticles/categoryColumn/CategoryColumn";
import AvailabilityColumn
    from "../../../components/pageComponents/articoli/tableArticles/availabilityColumn/AvailabilityColumn";
import ActionColumn from "../../../components/pageComponents/articoli/tableArticles/actionColumn/ActionColumn";
import {mapArticleAttributes} from "../../../utils/ArticleUtils";

const ArticlesContext = createContext({
    fetchArticles: () => {
    }
});

type UseWrappedArticleColumns<T> = (columns: DataTableColumn<T>[]) => DataTableColumn<T>[];

const Articoli = () => {

    const [getArticleDetails, isLoadingGetArticleDetails, isErrorGetArticleDetaiils] = useLazyGetArticleDetailsQuery();
    const [articles, setArticles] = useState<ArticleDetails[]>([]);
    const [openedArticleModal, setOpenedArticleModal] = useState(false);
    const theme = useMantineTheme()
    const callback = useCallback


    const mapArticleDetailsToRow = (article: ArticleDetails): TableArticleDetailsRow => {
        return {
            name: {
                image: article.headerArticleDetails.imageLink,
                title: article.headerArticleDetails.title
            },
            code: article.headerArticleDetails.code,
            ean: article.headerArticleDetails.ean,
            category: article.headerArticleDetails.category,
            availability: article.headerArticleDetails.availability,
            details: article
        }
    }

    const mappedArticles = articles.map(a => mapArticleDetailsToRow(a))

    const renderActionColumn = useCallback((article: TableArticleDetailsRow, index: number) => {
        console.log("Rerendering Action Column")
        return (
            <ActionColumn article={article.details} />
        )
    }, [])

   const ArticleColumns2: DataTableColumn<TableArticleDetailsRow>[] = [
        {
            accessor: 'name',
            title: 'Articolo',
            width: '40%',
            render: (article, index) => (
                <Flex gap={"md"}>
                    <Flex align={'center'} justify={'center'} style={{minWidth: '75px', minHeight: '75px', width: '75px'}}>
                        <img style={{maxHeight: '75px', maxWidth: '75px', objectFit: 'cover'}}
                             src={article.name.image as string}/>
                    </Flex>
                    <Flex align={'center'}>
                        <Text size={'sm'} fw={500}> {article.name.title}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            accessor: 'category',
            title: 'Categoria',
            width: '5%',
            noWrap: false,
            sortable: true,
            render: (article, index) => (
                <CategoryColumn category={article.category} />
            )
        },
        {
            accessor: 'code',
            title: 'Codice',
            width: '10%',
            sortable: true,
            render: (article, index) => (
                <Text size={'xs'}> {article.code}</Text>
            )
        },
        {
            accessor: 'ean',
            title: "EAN",
            width: '10%',
            sortable: true,
            render: (article, index) => (
                <Text size={'xs'}> {article.ean}</Text>
            )
        },
        {
            accessor: 'availability',
            title: "Disponibilità",
            width: '10%',
            sortable: true,
            render: (article, index) => (
                <AvailabilityColumn availability={article.availability} />
            )
        },
        {
            accessor: 'details',
            title: 'Azioni',
            width: '5%',
            render: renderActionColumn
        }
    ]


    const fetchArticles = () => {
        getArticleDetails().then(resp => {
            if (resp.data === undefined) {
                // TODO Do something
            } else {
                setArticles(resp.data);
            }
        })
    }

    const useArticles = () => useContext(ArticlesContext);

    useEffect(() => {
        fetchArticles();
    }, [])




    return (
        <ShellLayout
            Body={
                <>
                    <Space h={'lg'}></Space>
                    <Container size={'95%'}>
                        <Flex justify={'space-between'}>
                            <Title order={1} fw={500} c={theme.colors.textBlack[0]} mb={'xl'}>Articoli</Title>
                            <ConnectionStatus/>
                        </Flex>
                        <ArticlesContext.Provider value={{fetchArticles}}>
                            <TableArticles
                                columns={ArticleColumns2}
                                rows={mappedArticles}
                                loading={isLoadingGetArticleDetails.isFetching}/>
                        </ArticlesContext.Provider>

                    </Container>

                </>

            }
        />
    )
}

export const useArticles = () => useContext(ArticlesContext);
export default Articoli